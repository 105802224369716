import { Status } from "./Status"
import { Grupo } from "./Grupo"

export class Processamento {

    public uuid?: string
    public codigoCia?: string
    public usuarioResponsavel?: string
    public dataInicio?: Date
    public dataTermino?: Date
    public status?: Status
    public grupo!: Grupo
        
    }
    