import { Chart } from 'chart.js';
import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { MetricsService } from './queue-metrics.service';

@Component({
  selector: 'app-queue-metrics',
  template: `
    <app-menu-bar></app-menu-bar>
    <div>
      <h2>Métricas da Fila</h2>
      <ul>
        <li>Processamento: {{ processamento }}</li>
        <li>Envio: {{ envio }}</li>
        <li>Retorno: {{ retorno }}</li>
        <li>Erros: {{ erros }}</li>
        <li>Totais: {{ totais }}</li>
      </ul>
        
    </div>
    <div>
    <canvas id="queueChart"></canvas>
    </div>
  `,
})
export class QueueMetricsComponent implements OnInit {
  processamento = 0;
  envio = 0;
  retorno = 0;
  erros = 0;
  totais = 0;

  constructor(private metricsService: MetricsService) {}

  ngOnInit(): void {
    this.fetchMetrics();

    interval(1000) // Atualiza a cada 1 segundo
    .subscribe(() => this.fetchMetrics()
    );

  }

  fetchMetrics(): void {
    this.metricsService.getMetricByTag('queue.size', 'Fila', 'Processamento')
      .subscribe(data => this.processamento = data.measurements[0]?.value || 0);

    this.metricsService.getMetricByTag('queue.size', 'Fila', 'Envio')
      .subscribe(data => this.envio = data.measurements[0]?.value || 0);

    this.metricsService.getMetricByTag('queue.size', 'Fila', 'Retorno')
      .subscribe(data => this.retorno = data.measurements[0]?.value || 0);

    this.metricsService.getMetricByTag('queue.size', 'Fila', 'Erros')
      .subscribe(data => this.erros = data.measurements[0]?.value || 0);

    this.metricsService.getMetricByTag('queue.size', 'Fila', 'Totais')
      .subscribe(data => this.totais = data.measurements[0]?.value || 0);
      const dataLabels = ['Processamento', 'Envio', 'Retorno', 'Erros', 'Totais'];
      const dataValues: number[] = [];
    
      // Buscar cada métrica e popular os valores
      const fetchPromises = dataLabels.map(label =>
        this.metricsService.getMetricByTag('queue.size', 'Fila', label)
          .toPromise()
          .then(data => dataValues.push(data.measurements[0]?.value || 0))
      );
    
      Promise.all(fetchPromises).then(() => {
        this.renderChart(dataLabels, dataValues);
      });
    
  }
  renderChart(labels: string[], data: number[]): void {
    const ctx = document.getElementById('queueChart') as HTMLCanvasElement;
  
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [{
          label: 'Métricas da Fila',
          data,
        }],
      },
    });
    }
  
}

