import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MetricsService {
  apiUrl: string = `${environment.auth}/actuator/metrics`;

  constructor(private http: HttpClient) {}

  // Obter valores de uma métrica com tag específica
  getMetricByTag(metricName: string, tagKey: string, tagValue: string): Observable<any> {
    const url = `${this.apiUrl}/${metricName}?tag=${tagKey}:${tagValue}`;
    return this.http.get(url);
  }
}
