import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProcessamentoDocumentos } from '../modelo/ProcessamentoDocumentos';

@Injectable({
  providedIn: 'root'
})

export class ProcessamentoDocumentosService {

  apiUrl: string = `${environment.backendUrl}/processamento`;

  constructor(private http: HttpClient) { }

  getDocumentos(listaId: String | undefined | null): Observable<ProcessamentoDocumentos[]> {
    return this.http.get<ProcessamentoDocumentos[]>(`${this.apiUrl}/documentos/${listaId}`);
  }

}
